.justify-content--between {
   justify-content: space-between;
}

a:-webkit-any-link {
   text-decoration: none;
}

.swal2-container {
   z-index: 9999 !important;
}

.center-loading-icon {
   display: flex;
   justify-content: center;
   margin-top: 30px;
}
.right-loading-icon {
   display: flex;
}

.justify-content--end {
   display: flex;
   justify-content: flex-end;
}

.MuiMobileStepper-root.MuiMobileStepper-positionStatic {
   display: none;
}
