.public-container {
   display: flex;
   height: 100%;
}

.hero-image {
   max-height: 100%;
}

.home__container {
   max-height: 100%;
}
.logo__container {
   background-color: #00aca6;
   display: flex;
   justify-content: center;
}
div#logo-container.logo__container > div.mui-image-wrapper > img#logo.mui-image-img {
   width: 15vw;
}

.home__container.content {
   justify-content: center;
   height: 85%;
}

.home__container.content-section-buttons > .title {
   font-size: 2.4rem;
   font-weight: bold;
   margin-top: 0;
   margin-bottom: 30px;
   text-align: center;
}

.home__container.content-section-buttons {
   align-self: flex-end;
}

.home__container.content-section-buttons > a {
   margin-bottom: 18px;
}

.home__container.content-login-button {
   align-self: flex-end;
   margin-bottom: 8px;
}

button .arrow {
   width: 5%;
   transition: all 0.3s ease;
}

button:hover .arrow {
   width: 10%;
}

.arrow {
   margin-right: 2em;
   margin-left: 1em;
   border: 1px solid #009087;
   position: relative;
   background: #009087;
}

.arrow .head,
.arrow .head:after {
   border: 1.5px solid #009087;
   border-radius: 2px;
   width: 9px;
   position: absolute;
   right: -4px;
   top: 2px;
   transform: rotate(-45deg);
}

.arrow .head:after {
   content: "";
   border-color: #009087;
   right: -5px;
   top: -5px;
   transform: rotate(90deg);
}
