header > div.card-container {
   background-color: #00aca6;
   text-align: -webkit-center;
   border-radius: 0;
   display: flex;
   justify-content: center;
}

.card-container > .card-media {
   width: 18%;
}
