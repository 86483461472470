.form-container {
   border-radius: 4px;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
   padding: 15px 22px;
}

.form-container--text {
   text-align: center;
}

.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
   margin: 8px 0;
}

p.powered-by {
   color: rgba(0, 0, 0, 0.54);
   text-align: right;
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.43;
   letter-spacing: 0.01071em;
   margin-right: 4px;
}

.account-data > p > span {
   font-weight: bold;
}
