body {
   font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
      "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   margin: 0;
   padding: 0;
   min-height: 100%;
   width: 100%;
}

html,
body {
   height: 100%;
}

#root {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
