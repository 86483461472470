.nav-bar__logo.image {
   width: 18%;
}
.nav-bar__logo.container {
   display: flex;
   justify-content: center;
}

.nav-bar__options {
   background-color: white;
   justify-content: space-between;
}

.nav-bar__options > .main-buttons {
   display: flex;
   column-gap: 30px;
}

.nav-bar__options > div > a,
.nav-bar__options > button {
   font-size: 1rem;
   font-weight: bold;
}
